<template>
  <b-modal id="addNewRole" title="Add New Role" @hidden="resetInfoModal" scrollable ok-title="Save" @ok="submitForm" size="xl">
    <b-container>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group class="input-group-merge" :class="validation ? 'is-valid' : 'is-invalid'" label="Role Name:">
                <validation-provider #default="{ errors }" name="Role Name" rules="required">
                  <b-form-input v-model="role.name" list="input-list" placeholder="Enter Role Name" :state="validation" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group class="input-group-merge" :class="validation ? 'is-valid' : 'is-invalid'" label="Role Description:">
                <validation-provider #default="{ errors }" name="Role Description:" rules="required">
                  <b-form-input v-model="role.description" list="input-list" placeholder="Enter Role Description" :state="validationdes" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            ><b-col cols="9"><h4>Add Permissions</h4></b-col></b-row
          >

          <b-row>
            <b-col cols="4" xl="3" md="6" sm="12" class="bodyRow" style="background:#f1f1ff">
              <b-row class="m-1">
                <h4>Inventory</h4>
              </b-row>
              <b-row v-for="permission in permissions" v-bind:key="permission.id" v-if="permission.group === 'inventory'">
                <b-col cols="10">{{ permission.description }}</b-col>
                <b-col cols="2">
                  <b-form-checkbox switch size="sm" :value="permission" v-model="selected"> </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="4" xl="3" md="6" sm="12" class="bodyRow" style="background:#f8eee5">
              <b-row class="m-1">
                <h4>Quotes Purchase</h4>
              </b-row>
              <b-row v-for="permission in permissions" v-bind:key="permission.id" v-if="permission.group === 'purchase'">
                <b-col cols="10">{{ permission.description }}</b-col>
                <b-col cols="2">
                  <b-form-checkbox switch size="sm" :value="permission" v-model="selected"> </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" xl="3" md="6" sm="12" class="bodyRow" style="background:#f8e5e8">
              <b-row class="m-1">
                <h4>Quotes Sale</h4>
              </b-row>
              <b-row v-for="permission in permissions" v-bind:key="permission.id" v-if="permission.group === 'sale'">
                <b-col cols="10">{{ permission.description }}</b-col>
                <b-col cols="2">
                  <b-form-checkbox switch size="sm" :value="permission" v-model="selected"> </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" xl="3" md="6" sm="12" class="bodyRow" style="background:#f5fff5">
              <b-row class="m-1">
                <h4>Customer</h4>
              </b-row>
              <b-row v-for="permission in permissions" v-bind:key="permission.id" v-if="permission.group === 'customer'">
                <b-col cols="10">{{ permission.description }}</b-col>
                <b-col cols="2">
                  <b-form-checkbox switch size="sm" :value="permission" v-model="selected"> </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" xl="3" md="6" sm="12" class="bodyRow" style="background:#f5fff5">
              <b-row class="m-1">
                <h4>User</h4>
              </b-row>
              <b-row v-for="permission in permissions" v-bind:key="permission.id" v-if="permission.group === 'user'">
                <b-col cols="10">{{ permission.description }}</b-col>
                <b-col cols="2">
                  <b-form-checkbox switch size="sm" :value="permission" v-model="selected"> </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" xl="3" md="6" sm="12" class="bodyRow" style="background:aliceblue">
              <b-row class="m-1">
                <h4>Roles / Other</h4>
              </b-row>
              <b-row v-for="permission in permissions" v-bind:key="permission.id" v-if="permission.group === 'role' || permission.group === 'other'">
                <b-col cols="10">{{ permission.description }}</b-col>
                <b-col cols="2">
                  <b-form-checkbox switch size="sm" :value="permission" v-model="selected"> </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="4" xl="3" md="6" sm="12" class="bodyRow" style="background:#fffdf0">
              <b-row class="m-1">
                <h4>Leads</h4>
              </b-row>
              <b-row v-for="permission in permissions" v-bind:key="permission.id" v-if="permission.group === 'lead'">
                <b-col cols="10">{{ permission.description }}</b-col>
                <b-col cols="2">
                  <b-form-checkbox switch size="sm" :value="permission" v-model="selected"> </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          {{/*selected*/}}
        </b-form>
      </validation-observer>
    </b-container>
  </b-modal>
</template>

<script>
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import settingsStoreModule from '../../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  props: ['getRoles', 'loading'],
  data() {
    return {
      required,
      selected: [],
      permissions: [],
      role: {
        name: '',
        description: '',
        permissions: '',
      },
      submitted: false,
    };
  },
  methods: {
    submitForm() {
      this.$emit('loading', true);
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.submitted = true;
          this.$bvModal.hide('addNewRole');
          this.role.permissions = this.selected;
          store.dispatch('settings/addRole', this.role).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Role Add Successfully',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getRoles();
              this.resetInfoModal();
            }
          }); // dispatch store action
        }
      });
    },
    getPermissions() {
      store
        .dispatch('settings/fetchPermissions', [])
        .then((res) => {
          this.permissions = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetInfoModal() {
      this.submitted = false;
      this.role.name = '';
      this.role.description = '';
      this.role.permissions = '';
      this.selected = [];
    },
  },
  computed: {
    validation() {
      return this.role.name.length > 1;
    },
    validationdes() {
      return this.role.description.length > 1;
    },
  },
  created() {
    this.getPermissions();
  },
};
</script>
